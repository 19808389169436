import { configureStore } from '@reduxjs/toolkit'
import Auth from './reducers/auth'
import Globals from './reducers/globals'
import User from './reducers/user'
import Cloudinary from './reducers/cloudinary'
import Modal from './reducers/modal'

export const store = configureStore({
    reducer: {
        Auth,
        Cloudinary,
        Globals,
        User,
        Modal,
    },
})