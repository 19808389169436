import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

export const myFirebase = initializeApp({
    apiKey: process.env.GATSBY_FS_API_KEY,
    databaseURL: process.env.GATSBY_FS_DB_URL,
    authDomain: process.env.GATSBY_FS_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FS_PROJECT_ID,
    storageBucket: process.env.GATSBY_FS_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FS_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_FS_APP_ID,
});

// Initialize Firestore
export const db = getFirestore(myFirebase);

// Initialize Functions
export const functions = getFunctions(myFirebase);

// If you're developing locally, use the functions emulator
if (process.env.NODE_ENV === 'development') {
    console.log(process.env.GATSBY_FS_PROJECT_ID)
    connectFunctionsEmulator(functions, 'localhost', 5001); // Replace 'localhost' and '5001' with your emulator host and port if different
}