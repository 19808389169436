import React, { useEffect, useState } from 'react';
import { db } from '../system/firebase/index';
import { onSnapshot, query, collection } from 'firebase/firestore';
import { connect } from 'react-redux'
import Layout from '../templates/Master'
import Analytics from '../modules/Analytics'


const AnalyticsPage = () => {
   const [data, setData] = useState([]);
   const [viewerDetails, setViewerDetails] = useState([]);
   function convertTimeFromSecondsAndNanoseconds(data) {
       const { seconds, nanoseconds } = data;
       const milliseconds = seconds * 1000 + nanoseconds / 1e6; // convert nanoseconds to milliseconds
       const date = new Date(milliseconds);
       return date.toUTCString();
   }
 
   const convertTimestampToDate = (timestamp) => {
       const milliseconds = (timestamp.seconds * 1000) + (timestamp.nanoseconds / 1000000);
       return new Date(milliseconds);
   };

   useEffect(() => {
       const chartData = {};

       const q = query(collection(db, 'visits'));

       onSnapshot(q, (snapshot) => {
           const output = [];
           snapshot.docs.forEach((doc) => {
               const data = doc.data();
               const date = convertTimestampToDate(data.timestamp);
               const year = date.getFullYear();
               const company = data.user_company ? data.user_company.toUpperCase() : 'N/A';
               const monthYear = `${new Date(date).toLocaleString('default', { month: 'short' }).toUpperCase()}-${year}`;


               if (!chartData[monthYear]) {
                   chartData[monthYear] = { name: monthYear, SAB: 0, SABM: 0, HEINEKEN: 0, RETAILER: 0, OTHER: 0, 'N/A': 0 };
               }

               chartData[monthYear][company] += 1;

               output.push({
                   stp: data.pdf_stp,
                   company: data.user_company ? data.user_company.toUpperCase() : 'N/A',
                   location: data.geo.city,
                   date: convertTimeFromSecondsAndNanoseconds(data.timestamp),
               });
           });

           // Sort chartData by date
           const sortedChartData = Object.values(chartData).sort((a, b) => {
               const dateA = new Date(a.name.split('-')[1], new Date(Date.parse(a.name.split('-')[0] +" 1, 2012")).getMonth());
               const dateB = new Date(b.name.split('-')[1], new Date(Date.parse(b.name.split('-')[0] +" 1, 2012")).getMonth());
               return dateA - dateB;
           });

           // Sort output by date
           const sortedViewerDetails = output.sort((a, b) => new Date(b.date) - new Date(a.date));

           setData(sortedChartData);
           setViewerDetails(sortedViewerDetails);
       });
   }, []);
   return (
       <Layout>
           <Analytics data={data} viewerDetails={viewerDetails}/>
       </Layout>
   )
}

function mapStateToProps(state) {
   return {
       edit: state.Globals.isEdit,
       addNew: state.Globals.isAddNew,
   }
}

export default connect(mapStateToProps)(AnalyticsPage)

export const Head = () => <title>Listings Page</title>