// https://mui.com/customization/palette/

const palette = {
    background: {
        default: '#FFFFFF'
    },
    black: {
        main: '#000000',
        off: '#0F0F0F'
    },
    white: {
        main: '#FFFFFF'
    },
    grey: {
        main: '#E0E0E0',
        darkLite: '#B6B6B6',
        dark: '#818181',
        lite: '#B3B3B3'
    },
    red: {
        main: '#FF0000',
    }
}

export default palette