import React from 'react'
import { Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBahai } from '@fortawesome/free-solid-svg-icons'

const Loading = ({ color = 'white.main', size }) => {
    return (
        <Grid container={true} height="100%" width="100%">
            <Grid color={color} fontSize={size || '25px'} height={size} item xs={12} width={size} sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <FontAwesomeIcon icon={faBahai} spin />
            </Grid>
        </Grid>
    )
}

export default Loading;
