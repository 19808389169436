import React from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../models/Users'
import { Box, Grid, Typography } from '@mui/material'
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ dispatch }) => {
    const logout =() => {
        logoutUser(dispatch)
    }
    return (
        <Grid container={true} height='100%' width='100%' justifyContent= 'space-between'>
            <Grid item xs={6}>
                <Box>
                    <StaticImage src="../assets/images/barcodeBlack.png" alt="" />
                </Box>
            </Grid>
            <Grid item xs={6} textAlign='right' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Box onClick={logout} sx={{ cursor: 'pointer' }}>LOGOUT</Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Typography mt={1} variant='h1small'>CCS MANAGER</Typography>
                </Box>
                <Box>
                    <Typography color='grey.lite' variant='h3' sx={{ letterSpacing: 4 }}>UPLOAD / MANAGE PLANOGRAM PDFS </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(Header)
