import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Grid, Container } from '@mui/material'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Loading from '../components/Loading'
import { verifyAuth, logoutUser } from '../models/Users'

const Master = ({ dispatch, children, isVerifying, isAuthenticated }) => {
    const [loading, isLoading] = useState(
        <Grid alignItems='center' container={true} justifyContent='center' sx={{ display: 'flex' }} height='100vh' width='100%'>
            <Grid item>
                <Box width='25px'><Loading color='black.main' /></Box>
            </Grid>
        </Grid>
    )

    useEffect(() => {
        verifyAuth(dispatch)
    }, [])
    useEffect(() => {
        if (!isVerifying && !isAuthenticated) {
            logoutUser(dispatch)
        } else if(isAuthenticated) {
            isLoading(false)
        }
    }, [isVerifying, isAuthenticated])
    const theme = useTheme()
    const desktopView = useMediaQuery(theme.breakpoints.up('lg'))
    return (
        loading ? 
            loading 
            : 
            <Container maxWidth="xxl" sx={{ marginTop: desktopView ? 10 : 5 }}>
                <Box sx={{ zIndex: 1, position: 'relative' }}>
                    <Header />
                    <Box mt={desktopView ? 10.6 : 5.6} pb={10}>
                        {children}
                    </Box>
                    <Footer />
                </Box>
            </Container>
    )
}
function mapStateToProps(state) {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isVerifying: state.Auth.isVerifying,
    }
}
export default connect(mapStateToProps)(Master)
