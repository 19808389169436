import { doc, getDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { db, myFirebase } from '../system/firebase/index'
import { requestLogin, loginError, requestLogout, receiveLogout, logoutError, verifyRequest, setAuthUser, receiveLogin, verifySuccess, verifyFail } from '../system/redux/reducers/auth'
import { setMessage } from '../system/redux/reducers/user'
import { getMessage } from '../helpers/Messages'
import { navigate } from 'gatsby'

export const loginUser = (dispatch, params) => {
    dispatch(requestLogin())

    // First we check to see if the user exists in Firestore as an authenticated user
    const auth = getAuth(myFirebase)
    return signInWithEmailAndPassword(auth, params.email, params.password)
        .then(async (data) => {
            // We have a valid Firebase authenticated user
            await verifyAuth(dispatch)
            dispatch(setMessage(''))
            navigate('/listings')
            return true
        })
        .catch(async(error) => {
            // We do NOT have a valid Firebase authenticated user
            dispatch(setMessage(getMessage('loginFail')))
            dispatch(loginError())
            return false
        })
}

export const logoutUser = (dispatch) => {
    dispatch(requestLogout())
    const auth = getAuth(myFirebase)
    signOut(auth)
        .then(() => {
            dispatch(receiveLogout())
            navigate('/')
        })
        .catch((error) => {
            // Do something with the error if you want!
            dispatch(setMessage(''))
            dispatch(logoutError())
        })
}

export const verifyAuth = (dispatch) => {
    dispatch(verifyRequest())
    const auth = getAuth(myFirebase)
    onAuthStateChanged(auth, (user) => {
        if (user !== null) {
            const docRef = doc(db, 'users', user.uid)
            getDoc(docRef).then((doc) => {
                if (doc.exists()) {
                    const userData = JSON.stringify(doc.data())
                    const userSerialized = JSON.stringify(user)
                    dispatch(setAuthUser(userData))
                    dispatch(receiveLogin(userSerialized))
                    dispatch(verifySuccess())
                } else {
                    dispatch(loginError())
                }
            })
        } else {
            dispatch(verifyFail())
        }
    })
}
