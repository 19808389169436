import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as MuiLink } from '@mui/material'

const NavLink = ({ children, external, to }) => {
    return (
        <>
            { external?
                <MuiLink
                    color='inherit'
                    sx={{
                        textDecoration: 'none',
                    }}
                    href={to}
                    target='_blank'>
                    {children}
                </MuiLink>
                :
                <GatsbyLink
                    style={{
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                    to={to} >
                    {children}
                </GatsbyLink>
            }
        </>
    )
}

export default NavLink