// https://mui.com/customization/theme-components/

const components = {
    MuiButton: {
        variants: [
            {
                props: { variant: 'primary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: 4,
                    color: theme.palette.black.off,
                    cursor: 'pointer',
                    fontSize: '16px',
                    padding: '10px 0',
                    width: '100%',
                    ':hover': {
                        backgroundColor: theme.palette.black.main,
                        color: theme.palette.white.main,
                    }
                }),
            },
            {
                props: { variant: 'secondary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.grey.main,
                    borderRadius: 6,
                    color: theme.palette.grey.dark,
                    cursor: 'pointer',
                    fontFamily: 'IBM Plex sans',
                    fontWeight: 600,
                    fontSize: '14px',
                    padding: '10px 3px',
                    textTransform: 'none',
                    width: '100%',
                    ':hover': {
                        backgroundColor: theme.palette.black.main,
                        color: theme.palette.white.main,
                    }
                }),
            },
            {
                props: { variant: 'tertiary' },
                style: ({ ownerState, theme }) => ({
                    alignItems: 'center',
                    backgroundColor: theme.palette.black.main,
                    borderRadius: 4,
                    color: theme.palette.white.main,
                    cursor: 'pointer',
                    fontSize: '16px',
                    padding: '10px',
                    width: '100%',
                    ':hover': {
                        backgroundColor: theme.palette.white.main,
                        color: theme.palette.black.main,
                    }
                }),
            },
            {
                props: { variant: 'submit' },
                style: ({ ownerState, theme }) => ({
                    backgroundColor: theme.palette.black.main,
                    color: theme.palette.white.main,
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontFamily: 'IBM Plex sans',
                    fontWeight: 400,
                    padding: '0px',
                    margin: '0px',
                    lineHeight: '0px',
                    verticalAlign: 'baseline',
                    textTransform: 'none',
                    width: '100%',
                }),
            },
        ]
    }
}

export default components
