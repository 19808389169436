import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isCreatingSignature: false,
    signatureError: false,
    isSignatureAuthenticated: false,
    signature: null,
    errors: '',
    widget: false,
    loading: false,
}

export const cloudinarySlice = createSlice({
    name: 'cloudinary',
    initialState,
    reducers: {
        requestSignature: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isCreatingSignature = true
            state.signatureError = false
        },
        receiveSignature: (state, action) => {
            state.isCreatingSignature = false
            state.isSignatureAuthenticated = true
            state.signature = JSON.parse(action.payload)
        },
        signatureError: (state) => {
            state.isCreatingSignature = false
            state.isSignatureAuthenticated = false
            state.signatureError = true
            state.errors = 'The signature failed.'
        },
        widgetCreated: (state, action) => {
            state.widget = JSON.parse(action.payload)
        },
        widgetLoading: (state, action) => {
            state.loading = JSON.parse(action.payload)
        },
        unAuthorisedAccess: (state) => {
            state.unAuthorised = true
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    requestSignature,
    receiveSignature,
    signatureError,
    widgetCreated,
    widgetLoading,
} = cloudinarySlice.actions

export default cloudinarySlice.reducer
