import React, { useState, useEffect } from 'react';
import '../styles/app.css'
import NavLink from '../components/NavLink';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  CircularProgress,
  Popover,
  TextField,
  InputAdornment,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const Analytics = ({ name = 'Complete Analytics', data, viewerDetails, setAnalyticView, global = true }) => {
  const [isExporting, setIsExporting] = useState(false);
  const [exportComplete, setExportComplete] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverHeight, setPopoverHeight] = useState('auto');

  const exportToCSV = () => {
    setIsExporting(true);

    // Filter the viewerDetails based on date range
    const filteredViewerDetails = viewerDetails.filter(filterByDateRange);

    // Define the headers for the CSV file
    const headers = global
      ? ['STP', 'Company', 'Location', 'Day', 'Date', 'Time']
      : ['Company', 'Location', 'Day', 'Date', 'Time'];

    // Function to parse the date and format it properly
    const parseDate = (dateString) => {
      const dateObj = new Date(dateString);
      const day = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
      const date = dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).replace(',', '');
      const time = dateObj.toLocaleTimeString('en-US');

      return { day, date, time };
    };

    // Prepare rows for CSV using the filtered data
    const rows = filteredViewerDetails.map((detail) => {
      const { day, date, time } = parseDate(detail.date);
      return global
        ? [detail.stp, detail.company, detail.location, day, date, time]
        : [detail.company, detail.location, day, date, time];
    });

    // Create the CSV content
    const csvContent = [
      headers.join(','),
      ...rows.map((row) => row.join(',')),
    ].join('\n');

    // Create a blob for the CSV and initiate download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'viewer_details.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Set exporting state
    setIsExporting(false);
    setExportComplete(true);
    setTimeout(() => setExportComplete(false), 3000);
  };


  const filterByDateRange = (detail) => {
    const date = new Date(detail.date);
    if (startDate && endDate) {
      return date >= startDate && date <= endDate;
    }
    return true;
  };

  const handleDateClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverHeight('auto'); // Reset height when clicked
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverHeight('auto'); // Reset height on close
  };

  const handleDatePickerClick = () => {
    setPopoverHeight('450px'); // Set height when date picker is clicked
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // Change popover height based on whether a date is selected
    if (date) {
      setPopoverHeight('auto'); // Adjust to your desired height when a date is selected
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date) {
      setPopoverHeight('auto'); // Adjust to your desired height when a date is selected
    }
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Box border='1px solid black' borderRadius='3px'>
      <Box
        onClick={() => global ? null : setAnalyticView(false)}
        color='white.main'
        bgcolor='black.main'
        sx={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', display: "flex" }}
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={4}
        mb={4}
      >
        <Typography pr={2} variant='bold_analytics'>{name}</Typography>
        {global ?
          <NavLink to={`/listings`}>
            <Typography variant='bold_analytics' sx={{ textDecoration: 'none !important' }}>BACK</Typography>
          </NavLink> :
          <Typography variant='bold_analytics' sx={{ textDecoration: 'none !important', cursor: 'pointer' }}>BACK</Typography>
        }
      </Box>
      <Box pt={2} pb={4} px={4} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='bold_analytics'>Total Scans: {viewerDetails.length}</Typography>
        <Button
          variant="secondary"
          onClick={exportToCSV}
          disabled={isExporting}
          startIcon={isExporting ? <CircularProgress size={20} /> : null}
          sx={{ width: 'auto', paddingLeft: "20px", paddingRight: "20px" }}
        >
          {isExporting ? 'Exporting...' : exportComplete ? 'Download Complete' : 'Export CSV'}
        </Button>
      </Box>
      {viewerDetails.length > 0 ?
        <Grid container={true} pb={6} px={4}>
          <Grid item mobile={12} md={6}>
            <Typography variant='bold_analytics' mb={4} gutterBottom>Viewer Details</Typography>
            <Box height={450} sx={{ overflow: 'scroll' }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {global ? <TableCell>STP</TableCell> : null}
                      <TableCell>Company</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell onClick={handleDateClick} sx={{ cursor: 'pointer' }} display="flex">
                        <Box display="flex" alignItems="center">
                          <InputAdornment sx={{ pr: 1 }}>

                            <FilterListIcon />

                          </InputAdornment>
                          <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            Date
                          </Typography>

                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewerDetails.filter(filterByDateRange).map((detail, index) => (
                      <TableRow key={index}>
                        {global ? <TableCell>{detail.stp}</TableCell> : null}
                        <TableCell>{detail.company}</TableCell>
                        <TableCell>{detail.location}</TableCell>
                        <TableCell>
                          <Typography sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}>{detail.date}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid py={[4, 4, 4, 0]} item mobile={12} md={6} height={450}>
            <Typography variant='bold_analytics' mb={4} gutterBottom>Scans Over Time</Typography>
            <Box py={2}></Box>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="SAB" fill="#8884d8" />
                <Bar dataKey="SABM" fill="#8dd1e1" />
                <Bar dataKey="HEINEKEN" fill="#82ca9d" />
                <Bar dataKey="RETAILER" fill="#ffc658" />
                <Bar dataKey="OTHER" fill="#ff7300" />
                <Bar dataKey="N/A" fill="#e30332" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid> : null
      }
      {/* Popover for Date Range Picker */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { height: popoverHeight, width: '345px', padding: '16px', borderRadius: '4px' }, // Apply custom height here
        }}
      >
        <Box p={2} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>Date Filter</Typography>
            <Button
              variant="outlined"
              color="black"
              onClick={() => {
                setStartDate(null); // Clear the start date
                setEndDate(null);   // Clear the end date
                setPopoverHeight('auto'); // Reset popover height
              }}
              sx={{ minWidth: 'auto', width: '15px', minHeight: 'auto', height: '25px' }}
            >
              <RefreshIcon sx={{ width: '0.7em' }} />
            </Button>
          </Box>
          <hr width="100%" size="2" style={{ marginBottom: '10px', marginTop: '10px' }}></hr>
          {/* Row for Date Pickers */}
          <Box display="flex" flexDirection="row" gap={2}> {/* Adjust the gap for spacing */}

            {/* Start Date Field */}
            <Box display="flex" flexDirection="column" width="100%">
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }} mb={1}>From</Typography>
              <Box>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    handleStartDateChange(date);
                    setPopoverHeight('auto');
                  }}
                  onCalendarOpen={() => setPopoverHeight('450px')}
                  onCalendarClose={() => setPopoverHeight('auto')}
                  customInput={
                    <TextField
                      fullWidth
                      size="small"

                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon onClick={handleDatePickerClick} sx={{ width: '0.7em' }} />
                          </InputAdornment>
                        ),
                        style: { fontSize: '12px' }
                      }}
                      sx={{ fontSize: '12px' }}
                    />
                  }
                  placeholderText="MMM D, YYYY"
                  dateFormat="MM-d-yyyy"

                />
              </Box>
            </Box>

            {/* End Date Field */}
            <Box display="flex" flexDirection="column" width="100%">
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }} mb={1}>To</Typography>
              <Box>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    handleEndDateChange(date);
                    setPopoverHeight('auto');
                  }}
                  onCalendarOpen={() => setPopoverHeight('450px')}
                  onCalendarClose={() => setPopoverHeight('auto')}
                  style={{ fontSize: '12px' }}
                  customInput={
                    <TextField
                      fullWidth
                      size="small"
                      style={{ fontSize: '12px' }}
                      onClick={handleDatePickerClick}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon sx={{ width: '0.7em' }} />
                          </InputAdornment>
                        ),
                        style: { fontSize: '12px' }
                      }}
                      sx={{ fontSize: '12px' }}
                    />
                  }
                  placeholderText="MMM D, YYYY"
                  dateFormat="MM-d-yyyy"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Analytics;

