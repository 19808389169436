// https://mui.com/customization/typography/
import analytics from '../pages/analytics'
import palette from './palette'

const typography = {
    fontFamily: "'Montserrat', 'Open Sans', 'Arial'",
    fontSize: 14,
    fontWeight: 400,
    h1: {
        fontSize: 32,
    },
    h1small: {
        fontSize: 24,
    },
    h2: {
        fontSize: 14,
    },
    h3: {
        fontSize: 12,
    },
    link: {
        fontFamily: 'IBM Plex sans',
        fontSize: 12,
        fontWeight: 700,
    },
    ibm: {
        fontFamily: 'IBM Plex sans',
        fontWeight: 400,
        fontSize: '14px',
    },
    bold_analytics: {
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    small: {
        fontSize: 10.08,
        a: {
            color: palette.white.main,
            textDecoration: 'none',
        }
    },
}

export default typography